<template>
  <v-flex class="white text-body">
    <p id="content">
      El Regueton cubano, que alardea de un flow contagioso y un músculo financiero impresionante, en los últimos ocho años ha generado conflictos, censuras, disputas, éxitos y estrellas.
      <br />
      <br />Las canciones, los artistas y toda la industria —a veces legal, a veces ilegal, capaz al mismo tiempo de superar al Bloqueo norteamericano y al Ministerio de Cultura cubano— no han dejado de crecer. No es posible vivir en estos tiempos sin su denbow, ni comprender al país sin sus expresiones.
    </p>
  </v-flex>
</template>

<script>
export default {
  name: "TopDescriptionComponent"
  // data() {
  //   return {
  //     // view_full_text: false,
  //     // icon: "angle-up",
  //     presentation_text:

  //   };
  // },
  // methods: {
  //   viewFullText() {
  //     this.view_full_text = !this.view_full_text;
  //     this.icon = this.view_full_text ? "angle-down" : "angle-up";
  //   }
  // }
};
</script>

<style scoped>
#content {
  font-size: 16px;
  letter-spacing: 1px;
  /* line-height: 2; */
  padding: 25px;
}
/* .main-description-text {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 2;
  font-family: "AkzidenzGrotesk-Light", "Roboto Slaf", serif;
}

.description-text-container {
  background-color: white;
}

#header-description-text {
  overflow: hidden;
  color: black;
  margin-left: 20px;
  padding: 25px;
}

.view-full-text-btn {
  position: relative;
  left: 86vw;
  border: none;
} */
</style>
