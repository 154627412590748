<template>
  <v-layout column class="black" v-scroll="onScroll" data-view>
    <!-- <v-responsive>
      <video preload="yes" autoplay loop muted playsinline class="main-video hidden-sm-and-down">
        <source src="/img/home/portada.webm" type="video/webm" />
        <source src="/img/home/portada.mp4" type="video/mp4" />
      </video>
      <video preload="yes" playsinline autoplay muted loop class="main-video hidden-md-and-up">
        <source src="/img/home/portada-mov-.webm" type="video/webm" />
        <source src="/img/home/portada-mov-.mp4" type="video/mp4" />
      </video>
    </v-responsive> -->
    <v-img src="/img/home/portada-mov-.gif" class="hidden-md-and-up"></v-img>
    <v-img src="/img/home/portada.gif" class="hidden-sm-and-down"></v-img>
    <TopDescription class="hidden-md-and-up" />
    <Components />
    <v-layout class="white text-xs-center py-2 title-font text-uppercase video-section">
      <div class="pa-3 mx-auto">
        <v-img
          src="/img/home/Print del Especial-22.jpg"
          class="main video"
          @click="playMainVideo()"
          v-if="!playingMainVideo"
          :aspect-ratio="1.75"
        >
          <v-layout column justify-center align-center fill-height class="overlay">
            <v-img src="/img/home/play-square-control-button-for-multimedia.svg"></v-img>
          </v-layout>
        </v-img>
        <youtube
          :video-id="mainVideo"
          class="main video hidden-md-and-down"
          :class="{'hidden': !playingMainVideo}"
          player-width="600"
          player-height="300"
          :player-vars="{autoplay: 1}"
        ></youtube>
        <youtube
          :video-id="mainVideo"
          class="main video hidden-lg-and-up"
          :class="{'hidden': !playingMainVideo}"
          player-width="350"
          player-height="200"
          :player-vars="{autoplay: 1}"
        ></youtube>
        <div class="video-title">¿QUIÉN ES EL REY DEL REGGAETÓN EN CUBA?</div>
      </div>
      <v-layout>
        <div v-for="(video, i) in videos" :key="i" class="video-container pa-3">
          <v-img
            :src="video.thumb"
            class="video"
            v-if="!video.playing"
            @click="video.playing = true"
            :aspect-ratio="1.75"
            height="200"
            width="350"
          >
            <v-layout column justify-center align-center fill-height class="overlay">
              <v-img src="/img/home/play-square-control-button-for-multimedia.svg"></v-img>
            </v-layout>
          </v-img>
          <youtube
            class="video"
            :class="{'hidden': !video.playing}"
            :id="video.id"
            :video-id="video.videoId"
            player-width="350"
            player-height="200"
            :player-vars="{autoplay: 1}"
          ></youtube>
          <div class="video-title">{{ video.name }}</div>
        </div>
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import Components from "../../components/Home/Components";
import { getIdFromURL, YouTubePlayer } from "vue-youtube-embed";
import TopDescription from "../../components/Home/TopDescriptionComponent";
import { isMobile } from "mobile-device-detect";
// import Vue from 'vue';
// import VueYouTubeEmbed from 'vue-youtube-embed';
// Vue.use(VueYouTubeEmbed);

export default {
  name: "Home",
  head: {
    title: {
      inner: "Flow y poder",
      separator: " - ",
      complement: "Reguetón en Cuba"
    },
    meta: [
      { p: "og:title", c: "Flow y poder - Reguetón en Cuba" },
      {
        p: "og:description",
        c:
          "El Regueton cubano, que alardea de un flow contagioso y un músculo financiero impresionante, en los últimos ocho años ha generado conflictos, censuras, disputas, éxitos y estrellas."
      },
      {
        n: "description",
        c:
          "El Regueton cubano, que alardea de un flow contagioso y un músculo financiero impresionante, en los últimos ocho años ha generado conflictos, censuras, disputas, éxitos y estrellas."
      },
      { p: "og:url", c: "https://reggaeton.eltoque.com" },
      { p: "og:image", c: "https://reggaeton.eltoque.com/img/home/portada.jpg" }
    ]
  },
  // created() {
  //   window.addEventListener("scroll", this.showToolbarTitleOnScroll);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.showToolbarTitleOnScroll);
  // },
  mounted() {
    this.mainVideo = getIdFromURL("https://youtu.be/90YXsn8u0rM");
  },
  data() {
    return {
      mainVideo: "",
      // mainVideoTitle: '',
      playingMainVideo: false,
      otherVideoTitle: "",
      videos: [
        //   {
        //     id: 1,
        //     name: "Encuesta: Las canciones de reguetón más pegadas en Cuba",
        //     url: "https://www.youtube.com/watch?v=Iw6ZNFDtFXw",
        //     videoId: getIdFromURL("https://www.youtube.com/watch?v=Iw6ZNFDtFXw")
        //   },
        {
          id: 1,
          name: "Reparto, un reguetón a la cubana",
          url: "https://www.youtube.com/watch?v=SEy-x-Hs0vc&t=4s",
          videoId: getIdFromURL(
            "https://www.youtube.com/watch?v=SEy-x-Hs0vc&t=4s"
          ),
          thumb:
            "/img/home/Reparterismo pa ti Normalmente.mp4 - PotPlayer_2.png",
          playing: false
        },
        {
          id: 2,
          name: "Un reggaeton para Cristo",
          url: "https://www.youtube.com/watch?v=mp4YRQ8sSQ0 ",
          videoId: getIdFromURL("https://www.youtube.com/watch?v=mp4YRQ8sSQ0"),
          thumb: "/img/home/Print un regueton para Cristo 2.jpg",
          playing: false
        },
        {
          id: 3,
          name: "Reguetón cubano: cómo se hace una canción de reparto",
          url: "https://www.youtube.com/watch?v=uaW9LY7ua40",
          videoId: getIdFromURL("https://www.youtube.com/watch?v=uaW9LY7ua40"),
          thumb: "/img/home/01.png",
          playing: false
        }
      ]
    };
  },
  // computed: {
  //   mainVideoPlayer() {
  //     return this.$refs.main_video.player;
  //   }
  // },
  methods: {
    //   videoReady () {
    //       this.mainVideoTitle = this.mainVideoPlayer.getVideoData().title;
    //   },
    playMainVideo() {
      this.playingMainVideo = true;
      //   this.mainVideoPlayer.playVideo();
    },
    onScroll(e) {
      if (!isMobile) {
        // let currentRoute = this.$route.name;

        // if (currentRoute === "component.home") {
        const currentScrollPosition = e.target.scrollingElement.scrollTop;
        if (currentScrollPosition < 0) {
          return;
        }
        // Here we determine whether we need to show or hide the navbar
        this.$store.commit("setShowToolbarTitle", currentScrollPosition > 200);
      }
      // }
    }
  },
  components: {
    Components,
    TopDescription,
    youtube: YouTubePlayer
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit("setShowToolbarTitle", isMobile);
      vm.$store.commit("setViewCancionesBox", true);
      vm.$store.commit("setViewArtistasBox", true);
      vm.$store.commit("setViewLasBalasBox", true);
      vm.$store.commit("setViewJuegoBox", true);
      vm.$store.commit("setShowComponentsInFooter", false);
      next();
    });
  }
};
</script>

<style scoped>
.video-section {
  overflow-x: auto;
}

.video {
  width: 350px;
  height: 200px;
  margin: auto;
}
.video .overlay > .v-image {
  width: 50px;
  flex: 0 0 50px;
}

.video-container {
  flex: 1;
}

.video.main {
  width: 350px;
  height: 200px;
}

.video.hidden {
  display: none;
}

@media only screen and (min-width: 1264px) {
  .video.main {
    width: unset;
    height: 300px;
  }
  .video.main .overlay > .v-image {
    width: 70px;
    flex: 0 0 70px;
  }

  .video-section {
    flex-direction: column;
  }
}

.video-title {
  padding: 10px;
  font-size: 16px;
  letter-spacing: 3px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
/* .gif-portada {
        margin-top: 64px;
        max-height: 100vh;
    }

#box-container {
  padding: 0 !important;
  overflow-x: hidden;
  background-color: black;
}

.videos-container {
  background-color: white;
}

.videos-header {
  margin: 2vw 0;
  text-align: center;
}

.videos-header-text {
  color: rgba(70, 14, 242, 1);
  font-size: 5vw;
  letter-spacing: 5px;
}

.main-video {
  width: 400px;
  height: 300px;
}

.eltoque-video {
  width: 350px;
  height: 200px;
  margin: auto;
}

.main-video-title {
  text-align: center;
  margin: 1vw 0;
}

.videos-title-text {
  font-size: 16px;
  letter-spacing: 3px;
}

.other-video {
  flex: 1;
}

@media only screen and (min-device-width: 0) and (max-device-width: 740px) {
  .videos-container {
    padding: 5vw 0;
    flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    overflow-x: auto;
    overflow-y: hidden;
    height: auto;
  }

  .videos-header {
    margin-bottom: 6vw;
  }

  .main-video-container {
    margin: 0 5vw;
  }

  .main-video {
    width: 300px;
    height: 200px;
  }

  .main-video-title {
    height: 25px;
    width: 300px;
    padding: 10px;
  }

  .main-video iframe {
    width: 320px;
  }

  .eltoque-video {
    width: 300px;
    height: 200px;
  }

  .others-videos-container {
    margin-right: 10vw;
    padding-right: 5vw;
    width: 100%;
  }

  .other-video {
    margin: 0 3vw;
    padding-right: 5vw;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  .displayOnMobile {
    display: none;
  }

  .gif-desktop-portada {
            height: auto;
            margin-top: 10vh
        }

  #box-container {
    margin: 0;
    max-width: 100% !important;
    background-color: black;
  }

  .videos-container {
    margin-top: 2vw;
  }

  .main-video-container {
    margin: 2vw 35.5vw 0;
    margin: 2vw auto 0;
  }

  .main-video {
    width: 600px;
    height: 300px;
    position: relative;
    right: 8vw;
  }

  .videos-header-text {
    font-size: 1.5vw;
  }

  .videos-title-text {
    font-size: 1vw;
  }

  .others-videos-container {
    margin-left: 2vw;
    margin-right: 2vw;
    overflow-x: scroll;
  }

  .other-video {
    margin: 1vw;
  }
} */
</style>
